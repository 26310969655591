import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import GenericCopy from "../components/interior-components/GenericCopy";
import GalleryArchive from "../components/interior-components/GalleryArchive";
import { render } from "react-dom";

// eslint-disable-next-line
export const GalleryPageTemplate = ({
  contentComponent,
  gal_title,
  gal_image,
  gal_showTitle,
  gal_categories,
}) => {
  const PostContent = contentComponent || Content;

  /**
   * TEMPLATE MARKUP START
   */
  return (
    <div className="main-page-content">
      {/* Required Blocks */}
      <InteriorHero image={gal_image} title={gal_title} heroHeadline='' showTitle={gal_showTitle} thirdLevel={false} />
      
      <GalleryArchive categories={gal_categories} />

    </div>
  );
};

GalleryPageTemplate.propTypes = {
    gal_title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    gal_image: PropTypes.object,
    gal_showTitle: PropTypes.bool,
    gal_categories: PropTypes.array,
};

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data;
  

  return (
    <Layout>
      <div className="gallery-page">
        <GalleryPageTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          helmet={
            <Helmet titleTemplate="%s | Services">
              <title>{`${post.frontmatter.title}`}</title>
              <meta
                name="description"
                content={`${post.frontmatter.description}`}
              />
            </Helmet>
          }
          gal_title={post.frontmatter.gal_title}
          gal_image={post.frontmatter.gal_image}
          gal_showTitle={post.frontmatter.gal_showTitle}
          gal_categories={post.frontmatter.gal_categories}
        />
      </div>
    </Layout>
  );
};


GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default GalleryPage;

export const pageQuery = graphql`
query GalleryPageQuery {
  markdownRemark(frontmatter: { templateKey: { eq: "gallery-page" } }) {
    id
    html
    frontmatter {
      gal_title
      gal_showTitle
      gal_image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      gal_categories{
        title
        link{
          img{
            publicURL
            childImageSharp {
              gatsbyImageData(width: 480, quality: 85, layout: CONSTRAINED)
            }
          }
          copy
          linkimage
          linkpage
        }
      }
    }
  }
}
`;

