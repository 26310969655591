import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { render } from "react-dom";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function GenericCopySection(props) {
  const { copy, image, image_second } = props;

  const side_img_exists =
    (image && image.img) || (image_second && image_second.img);
  return (
    <>
      <section className="section pt-0 pb-0">
        <div className="container">
          <div className="section pt-0 pb-0">
            <div className="columns is-gapless">
              {copy && (
                <div
                  className={`column ${
                    side_img_exists ? "is-half" : "is-full"
                  } pl-5 pr-5 pt-3`}
                >
                  <div className="content px-5 py-6 generic_copy_content">
                    <ReactMarkdown
                      children={copy}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                </div>
              )}

              {side_img_exists && (
                <div className="column is-half romance-aside p-0">
                  {image && image.img && (
                    <div className="romance-aside__wrapper">
                      <PreviewCompatibleImage
                        imageInfo={image.img}
                        altTag={image.alt}
                      />
                    </div>
                  )}
                  {image_second && image_second.img && (
                    <div className="romance-aside__wrapper">
                      <PreviewCompatibleImage
                        imageInfo={image_second.img}
                        altTag={image_second.alt}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
