import React from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { render } from "react-dom";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function GalleryArchive(props) {
  const { categories } = props;

  return (
    <>
      {categories.map((item, indx) => (
        <section key={indx} className="section pt-0 pb-0 porfolio-gallery">
          <div className="container py-6">
            <div className="section pt-0 pb-0">
              <div className="columns is-gapless">
                <div className="column">
                  <div
                    className={
                      "porfolio-gallery__category porfolio-gallery__category-" +
                      indx
                    }
                  >
                    <h2 className="h1 my-5 redline-headline">{item.title}</h2>

                    <div className="porfolio-gallery__grid">
                      {item.link.map((link, lindx) => {
                        return link.linkimage === true ? (
                          <div
                            key={lindx}
                            className={
                              "porfolio-gallery__item porfolio-gallery__link-" +
                              lindx +
                              " mb-2"
                            }
                          >
                            <Link to={link.img.publicURL}>
                              <div className="porfolio-gallery__thumbnail-wrapper">
                                <div className="porfolio-gallery__thumbnail">
                                  <PreviewCompatibleImage
                                    key={lindx}
                                    _img
                                    imageInfo={link.img}
                                  />
                                </div>
                              </div>
                            </Link>
                            <div className="porfolio-gallery__link-header  my-3">
                              <ReactMarkdown
                                children={link.copy}
                                rehypePlugins={[rehypeRaw]}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            key={lindx}
                            className={
                              "porfolio-gallery__item porfolio-gallery__link-" +
                              lindx +
                              " mb-2"
                            }
                          >
                            <Link to={link.linkpage ? link.linkpage : "#"}>
                              <div className="porfolio-gallery__thumbnail-wrapper">
                                <div className="porfolio-gallery__thumbnail">
                                  <PreviewCompatibleImage
                                    key={lindx}
                                    _img
                                    imageInfo={link.img}
                                  />
                                </div>
                              </div>
                            </Link>
                            <div className="porfolio-gallery__link-header my-3">
                              <ReactMarkdown
                                children={link.copy}
                                rehypePlugins={[rehypeRaw]}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
}
